import RecipientForm from "../types/recipient_form";
import instance from "./http_client";

export function getRecipientForms(): Promise<RecipientForm[]> {
  return instance
    .get("consent/forms")
    .then((r) => r.data.map(RecipientForm.fromJson));
}

export function signForm(pk: number, signature: string) {
  return instance.patch(`consent/${pk}/sign`, { signature: signature });
}
