import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import MyRoutes from "./components/MyRoutes/MyRoutes";
import AuthPatientProvider from "./providers/AuthPatientProvider";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "MediCircle | Recipient";
  }, []);

  return (
    <Router>
      <AuthPatientProvider>
        <ProgressBar></ProgressBar>
        <MyRoutes />
      </AuthPatientProvider>
    </Router>
  );
}

export default App;
