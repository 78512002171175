import { useState } from "react";
import styles from "./ConsentSignature.module.css";
import { ApiStatus } from "../../types/api_status";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "../../components/Button/Button";
import RecipientForm from "../../types/recipient_form";
import { signForm } from "../../api/signature_api";
import { isMobile } from "react-device-detect";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import ConsentFormCanvas from "./ConsentFormCanvas/ConsentFormCanvas";
import Next from "../../assets/ArrowRight.svg";
import { ClipLoader } from "react-spinners";
import { useAuth } from "../../providers/AuthPatientProvider";
import { assert } from "../../utils";

interface IFormInput {
  fullName: string;
}

export default function ConsentSignature() {
  const [apiStatus, setApiStatus] = useState(ApiStatus.INITIAL);

  const navigate = useNavigate();
  const indexRaw = useParams().index ?? "0";
  const index = parseInt(indexRaw);

  const forms: RecipientForm[] = useLocation().state;
  const form = forms[index];

  const { patient } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<IFormInput>({
    mode: "onSubmit",
  });

  const signatureError = errors.fullName;

  const onSubmit = (data: IFormInput) => {
    setApiStatus(ApiStatus.LOADING);
    return signForm(form.pk, data.fullName)
      .then(() => {
        if (index < forms.length - 1) {
          navigate(`/consent/${index + 1}`, { state: forms });
          reset();
        } else {
          assert(patient);
          if (patient.financialFormCompleted) {
            navigate("/complete");
          } else {
            navigate("/questionnaire");
          }
        }
        setApiStatus(ApiStatus.SUCCESS);
      })
      .catch(() => {
        setApiStatus(ApiStatus.FAILURE);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.consentSignature}>
        {!isMobile && <ConsentFormCanvas url={form.file}></ConsentFormCanvas>}
        <div className={styles.actionsBox}>
          <h1 className={styles.title}>
            Sign recipient form
            {forms.length === 1 ? "" : ` (${index + 1}/${forms.length})`}
          </h1>
          <p className={styles.subtext}>
            By signing below, you acknowledge that you have read the attached
            form(s) and agree to receive recertified medications.
          </p>
          {isMobile && <ConsentFormCanvas url={form.file}></ConsentFormCanvas>}
          {!isMobile && (
            <div className={styles.inputBox}>
              <input
                autoFocus={true}
                className={signatureError ? styles.hasError : undefined}
                id={"fullName"}
                placeholder="Type your full name to sign"
                {...register("fullName", { required: true })}
              ></input>
              {signatureError && (
                <p className={styles.error}>Please enter your full name</p>
              )}
              <div className={styles.buttonWrap}>
                <Button
                  width="200px"
                  submit={true}
                  loading={apiStatus === ApiStatus.LOADING}
                >
                  Continue
                </Button>
              </div>
            </div>
          )}
        </div>{" "}
        {isMobile && (
          <div className={styles.mobileSignBox}>
            <input
              autoFocus={true}
              className={signatureError ? styles.hasError : undefined}
              id={"fullName"}
              placeholder="Type your full name to sign"
              {...register("fullName", { required: true })}
            ></input>
            <button
              disabled={!isDirty || !isValid || isSubmitting}
              type="submit"
            >
              {isSubmitting ? (
                <ClipLoader
                  color="white"
                  size={32}
                  speedMultiplier={0.75}
                ></ClipLoader>
              ) : (
                <img src={Next} alt="Next"></img>
              )}
            </button>
          </div>
        )}{" "}
      </div>
    </form>
  );
}
