import MoonLoader from "react-spinners/MoonLoader";
import styles from "./ConsentFormCanvas.module.css";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

/* Per https://stackoverflow.com/questions/3455016/how-are-pdf-sizes-specified, 
A4 PDFs are 595pt x 842pt at 72ppi. React-PDF renders at 72 ppi by default. 
We use this fact to scale the PDFs to fit their  containers. */

export default function ConsentFormCanvas(props: { url: string }) {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageScale, setPageScale] = useState<number>(1);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  useEffect(() => {
    if (isMobile) return;
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver((e) => {
      const height = e[0].contentBoxSize[0].blockSize;
      setPageScale(height / 800);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const getPages = () => {
    const pages = [];
    for (let i = 0; i < numPages; i++) {
      pages.push(
        <Page
          pageNumber={i + 1}
          renderTextLayer={false}
          className={styles.pdfPage}
          scale={isMobile ? window.innerWidth / 660 : pageScale}
          key={i}
        />
      );
    }
    return pages;
  };

  return (
    <div ref={ref} className={styles.document}>
      <Document
        loading={
          <div className={styles.loading}>
            <MoonLoader color="var(--color-primary)"></MoonLoader>
          </div>
        }
        file={props.url}
        className={styles.pdfFrame2}
        onLoadSuccess={({ numPages }) => {
          setNumPages(numPages);
        }}
      >
        {getPages()}
      </Document>
    </div>
  );
}
