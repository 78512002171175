import { Route, Routes } from "react-router";
import ProtectedRoute from "./ProtectedRoute";
import { Navigate } from "react-router-dom";
import PatientAuthentication from "../../pages/PatientAuthentication/PatientAuthentication";
import ConfirmInformation from "../../pages/ConfirmInformation/ConfirmInformation";
import ConsentSignature from "../../pages/ConsentSignature/ConsentSignature";
// import Questionnaire from "../../pages/Questionnaire/Questionnaire";
import Completion from "../../pages/Completion/Completion";
import Welcome from "../../pages/Welcome/Welcome";
import Questionnaire from "../../pages/Questionnaire/Questionnaire";

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/welcome"></Navigate>}></Route>
      <Route path="/welcome" element={<Welcome></Welcome>}></Route>
      <Route
        path="/authenticate"
        element={<PatientAuthentication></PatientAuthentication>}
      ></Route>
      <Route path="/confirmInformation" element={<ProtectedRoute />}>
        <Route
          path="/confirmInformation"
          element={<ConfirmInformation></ConfirmInformation>}
        ></Route>
      </Route>
      <Route path="/consent/:index" element={<ProtectedRoute />}>
        <Route
          path="/consent/:index"
          element={<ConsentSignature></ConsentSignature>}
        ></Route>
      </Route>
      <Route path="/questionnaire" element={<ProtectedRoute />}>
        <Route
          path="/questionnaire"
          element={<Questionnaire></Questionnaire>}
        ></Route>
      </Route>
      <Route path="/complete" element={<ProtectedRoute />}>
        <Route path="/complete" element={<Completion></Completion>}></Route>
      </Route>
      <Route path="*" element={<Navigate to="/welcome"></Navigate>}></Route>
    </Routes>
  );
}
