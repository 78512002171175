import { useLocation } from "react-router-dom";
import styles from "./ProgressBar.module.css";

function getLocationIndex(location: string) {
  switch (location) {
    case "confirmInformation":
      return 3;
    case "consent":
      return 4;
    case "questionnaire":
      return 5;
    case "complete":
      return 5;
    case "":
    case "authenticate":
      return 2;
    case "welcome":
    default:
      return 0;
  }
}

export default function ProgressBar() {
  const { pathname } = useLocation();

  const progress = getLocationIndex(pathname.split("/")[1]) / 5;

  return (
    <div className={styles.progressBar}>
      <div
        className={styles.progressBarActive}
        style={{ width: `${progress * 100}%` }}
      ></div>
    </div>
  );
}
