export interface PatientJson {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  display_address: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  email: string;
  phone_number: string;
  financial_form_completed: boolean;
}

export class Patient {
  constructor(
    public pk: number,
    public firstName: string,
    public lastName: string,
    public fullName: string,
    public displayAddress: string,
    public streetAddress: string,
    public city: string,
    public state: string,
    public zipCode: string,
    public email: string,
    public phoneNumber: string,
    public financialFormCompleted: boolean
  ) {}

  static fromJson(data: PatientJson): Patient {
    return new Patient(
      data.id,
      data.first_name,
      data.last_name,
      data.full_name,
      data.display_address,
      data.street_address,
      data.city,
      data.state,
      data.zip_code,
      data.email,
      data.phone_number,
      data.financial_form_completed
    );
  }
}
