import { Patient } from "../types/patient";
import instance from "./http_client";

export function getPatient() {
  return instance.get("consent/patient").then((r) => {
    return Patient.fromJson(r.data);
  });
}

export function updatePatient(
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string
) {
  return instance
    .patch(`/consent/patient/update`, {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      street_address: streetAddress,
      city: city,
      state: state,
      zip_code: zip,
    })
    .then((response) => Patient.fromJson(response.data));
}

export function submitFinancialData(
  difficultyAffordingExpenses?: boolean,
  medsDifficultToAccess?: boolean,
  doesNotFaceChallenges?: boolean,
  prefersNotToAnswer?: boolean,
  houseHoldIncomeBelowThreshold?: boolean,
  uninsured?: boolean
) {
  return instance.post(`/consent/patient/finances/submit`, {
    difficulty_affording_expenses: difficultyAffordingExpenses,
    meds_difficult_to_access: medsDifficultToAccess,
    does_not_face_any_challenges: doesNotFaceChallenges,
    prefers_not_to_answer: prefersNotToAnswer,
    household_income_below_threshold: houseHoldIncomeBelowThreshold,
    uninsured: uninsured,
  });
}
