import { useForm } from "react-hook-form";
import Button from "../../components/Button/Button";
import styles from "./Questionnaire.module.css";
import { ApiStatus } from "../../types/api_status";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { submitFinancialData } from "../../api/patient_api";

interface IFormInput {
  premiumsTooHigh: boolean;
  affordabilityDifficulty: boolean;
  accessChallenges: boolean;
  householdIncomeBelowThreshold: boolean;
  faceOtherChallenges: boolean;
  doNotAgree: boolean;
  uninsured: boolean;
  preferNotToAnswer: boolean;
}

export default function Questionnaire() {
  const [apiStatus, setApiStatus] = useState(ApiStatus.INITIAL);

  const navigate = useNavigate();

  const { register, handleSubmit, watch } = useForm<IFormInput>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const currentFields = Object.values(watch());

  const valid = currentFields.some((x) => x === true);

  const onSubmit = (data: IFormInput) => {
    setApiStatus(ApiStatus.LOADING);
    return submitFinancialData(
      data.affordabilityDifficulty
        ? data.affordabilityDifficulty
        : data.premiumsTooHigh,
      data.accessChallenges,
      data.doNotAgree,
      data.preferNotToAnswer,
      data.householdIncomeBelowThreshold
    )
      .then(() => {
        setApiStatus(ApiStatus.SUCCESS);
      })
      .catch(() => setApiStatus(ApiStatus.FAILURE))
      .finally(() => navigate("/complete"));
  };

  return (
    <form className={styles.questionnaire} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.content}>
        <h1 className={styles.title}>Financial Status Survey</h1>
        <p className={styles.subtitle}>
          At MediCircle, we are committed to providing affordable medications to
          people who need them. To help us serve you better, please let us know
          if you agree with any of the following:
        </p>{" "}
        <div className={styles.questions}>
          <div>
            <input
              {...register("premiumsTooHigh")}
              className={styles.checkbox}
              type="checkbox"
              id="premiumsTooHigh"
            ></input>
            <label className={styles.signatureLabel} htmlFor="premiumsTooHigh">
              My co-pay premiums or deductibles are too high
            </label>
          </div>{" "}
          <div>
            <input
              {...register("affordabilityDifficulty")}
              className={styles.checkbox}
              type="checkbox"
              id="affordabilityDifficulty"
            ></input>
            <label
              className={styles.signatureLabel}
              htmlFor="affordabilityDifficulty"
            >
              In general it is difficult for me to afford my health care or
              prescription drugs
            </label>
          </div>{" "}
          <div>
            <input
              {...register("accessChallenges")}
              className={styles.checkbox}
              type="checkbox"
              id="accessChallenges"
            ></input>
            <label className={styles.signatureLabel} htmlFor="accessChallenges">
              I have faced challenges accessing health care or prescription
              drugs
            </label>
          </div>{" "}
          <div>
            <input
              {...register("householdIncomeBelowThreshold")}
              className={styles.checkbox}
              type="checkbox"
              id="householdIncomeBelowThreshold"
            ></input>
            <label
              className={styles.signatureLabel}
              htmlFor="householdIncomeBelowThreshold"
            >
              My household income is below $83k
            </label>
          </div>{" "}
          <div>
            <input
              {...register("faceOtherChallenges")}
              className={styles.checkbox}
              type="checkbox"
              id="faceOtherChallenges"
            ></input>
            <label
              className={styles.signatureLabel}
              htmlFor="faceOtherChallenges"
            >
              I face other obstacles when obtaining health care (please specify)
            </label>
          </div>
          <div>
            <input
              {...register("uninsured")}
              className={styles.checkbox}
              type="checkbox"
              id="doNotAgree"
            ></input>
            <label className={styles.signatureLabel} htmlFor="doNotAgree">
              I do not have health insurance
            </label>
          </div>
          <div>
            <div>
              <input
                {...register("doNotAgree")}
                className={styles.checkbox}
                type="checkbox"
                id="doNotAgree"
              ></input>
              <label className={styles.signatureLabel} htmlFor="doNotAgree">
                I do not agree with any of the above statements
              </label>
            </div>
          </div>
          <div>
            <input
              {...register("preferNotToAnswer")}
              className={styles.checkbox}
              type="checkbox"
              id="preferNotToAnswer"
            ></input>
            <label
              className={styles.signatureLabel}
              htmlFor="preferNotToAnswer"
            >
              Prefer not to answer
            </label>
          </div>
        </div>
        <div className={styles.buttonWrap}>
          <Button
            submit={true}
            disabled={!valid}
            padding="14px 48px"
            loading={apiStatus === ApiStatus.LOADING}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
}
