import { createContext, useContext, useEffect, useState } from "react";
import { Patient } from "../types/patient";
import { getPatient } from "../api/patient_api";
import { ApiStatus } from "../types/api_status";
import AppLoading from "../pages/AppLoading/AppLoading";

interface AuthPatientContextValue {
  patient: Patient | undefined;
  loadPatient: () => Promise<void>;
  setPatient: (patient: Patient) => void;
}

const AuthPatientContext = createContext<AuthPatientContextValue>(
  {} as AuthPatientContextValue
);

export default function AuthPatientProvider(props: {
  children: React.ReactNode;
}) {
  const [patient, setPatient] = useState<Patient>();
  const [initialLoadStatus, setInitialLoadStatus] = useState<ApiStatus>(
    ApiStatus.LOADING
  );

  const loadPatient = () => {
    return getPatient().then(setPatient);
  };

  const handleSetPatient = (patient: Patient) => {
    setPatient(patient);
  };

  useEffect(() => {
    getPatient()
      .then((patient) => {
        setPatient(patient);
        setInitialLoadStatus(ApiStatus.SUCCESS);
      })
      .catch(() => setInitialLoadStatus(ApiStatus.FAILURE));
  }, []);

  const value = { patient, loadPatient, setPatient: handleSetPatient };

  return (
    <AuthPatientContext.Provider value={value}>
      {initialLoadStatus === ApiStatus.LOADING ? (
        <AppLoading></AppLoading>
      ) : (
        props.children
      )}
    </AuthPatientContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthPatientContext);
};
