export const REQUIRED_FIELD_ERROR_MESSAGE = "This field is required";

export const BASE_API_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000/v1"
    : "https://api.medicirclerx.com/v1";

export const DASHBOARD_URL = "/";
export const LOGIN_URL = "/login";
export const SIGNUP_URL = "/register";

export const matchDayRegex = /^(0?[1-9]|[12][0-9]|3[01])$/;
export const matchMonthRegex = /^(0?[1-9]|[12][0-2])$/;
export const matchYearRegex = /^(19|20)\d{2}$/;
