import { FormProvider, useForm } from "react-hook-form";
import styles from "./ConfirmInformation.module.css";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { updatePatient } from "../../api/patient_api";
import { useState } from "react";
import { ApiStatus } from "../../types/api_status";
import { getRecipientForms } from "../../api/signature_api";
import { useAuth } from "../../providers/AuthPatientProvider";
import { assert } from "../../utils";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../constants";

interface IFormInput {
  firstName: string;
  lastName: string;
  streetAddress: string;
  state: string;
  city: string;
  zipCode: string;
  email: string;
  phone: string;
}

export default function ConfirmInformation() {
  const [apiStatus, setApiStatus] = useState(ApiStatus.INITIAL);

  const { patient, setPatient } = useAuth();
  assert(patient);

  const navigate = useNavigate();

  const methods = useForm<IFormInput>({
    mode: "onSubmit",
    defaultValues: {
      firstName: patient.firstName,
      lastName: patient.lastName,
      streetAddress: patient.streetAddress,
      state: patient.state,
      city: patient.city,
      zipCode: patient.zipCode,
      email: patient.email,
      phone: patient.phoneNumber,
    },
  });

  const isDirty = methods.formState.isDirty;
  const errors = methods.formState.errors;

  const onSubmit = async (data: IFormInput) => {
    setApiStatus(ApiStatus.LOADING);
    try {
      if (isDirty) {
        const updated = await updatePatient(
          data.firstName,
          data.lastName,
          data.email,
          data.phone,
          data.streetAddress,
          data.city,
          data.state,
          data.zipCode
        );
        setPatient(updated);
      }

      const pdfForms = await getRecipientForms();
      navigate("/consent/0", { state: pdfForms });
    } catch (e) {
      setApiStatus(ApiStatus.FAILURE);
    }
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.confirmInformation}>
        <div className={styles.content}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <h1 className={styles.title}>Confirm your information</h1>
            <p className={styles.subtext}>
              Please make sure this information is accurate. This is where we
              will ship your medication.
            </p>
            <div className={styles.inputGrid}>
              <Input
                id={"firstName"}
                name={"firstName"}
                label="First name"
                autocomplete="off"
                registerOptions={{ required: true }}
                error={errors.firstName && REQUIRED_FIELD_ERROR_MESSAGE}
              ></Input>
              <Input
                id={"lastName"}
                name={"lastName"}
                label="Last name"
                registerOptions={{ required: true }}
                error={errors.lastName && REQUIRED_FIELD_ERROR_MESSAGE}
              ></Input>
              <Input
                className={styles.fullSpanInput}
                id={"email"}
                name={"email"}
                label="Email"
                registerOptions={{ required: true }}
                error={errors.email && REQUIRED_FIELD_ERROR_MESSAGE}
              ></Input>
              <Input
                className={styles.fullSpanInput}
                id={"phone"}
                name={"phone"}
                label="Phone"
                registerOptions={{ required: true }}
                error={errors.phone && REQUIRED_FIELD_ERROR_MESSAGE}
              ></Input>
              <Input
                className={styles.fullSpanInput}
                id={"streetAddress"}
                name={"streetAddress"}
                label="Street address"
                registerOptions={{ required: true }}
                error={errors.streetAddress && REQUIRED_FIELD_ERROR_MESSAGE}
              ></Input>
              <Input
                className={styles.fullSpanInput}
                id={"city"}
                name={"city"}
                label="City"
                registerOptions={{ required: true }}
                error={errors.city && REQUIRED_FIELD_ERROR_MESSAGE}
              ></Input>
              <Input
                id={"state"}
                name={"state"}
                label="State"
                registerOptions={{ required: true }}
                error={errors.state && REQUIRED_FIELD_ERROR_MESSAGE}
              ></Input>
              <Input
                id={"zipCode"}
                name={"zipCode"}
                label="Zip code"
                registerOptions={{ required: true }}
                error={errors.zipCode && REQUIRED_FIELD_ERROR_MESSAGE}
              ></Input>
            </div>
            <div className={styles.checkboxes}>
              {apiStatus === ApiStatus.FAILURE && (
                <p className={styles.networkError}>Error submitting data</p>
              )}
            </div>

            <Button
              width={"100%"}
              loading={apiStatus === ApiStatus.LOADING}
              submit={true}
            >
              Continue
            </Button>
          </form>
        </div>
      </div>
    </FormProvider>
  );
}
