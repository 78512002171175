export interface RecipientFormJson {
  id: number;
  unsigned_form: string;
}

export default class RecipientForm {
  constructor(public pk: number, public file: string) {}

  static fromJson(data: RecipientFormJson): RecipientForm {
    return new RecipientForm(data.id, data.unsigned_form);
  }
}
