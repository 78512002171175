import styles from "./Welcome.module.css";
import Button from "../../components/Button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../assets/Logo.svg";

export default function Welcome() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onContinue = () => {
    const accessCode = searchParams.get("access_code");
    if (accessCode) {
      navigate(`/authenticate?access_code=${accessCode}`);
    } else {
      navigate("/authenticate");
    }
  };

  return (
    <div className={styles.welcome}>
      <div className={styles.content}>
        <img className={styles.logo} src={Logo} alt="MediCircle"></img>
        <p className={styles.introText}>
          Thank you for choosing MediCircle. We provide brand-new, unopened
          medications that were previously dispensed but were never used. Our
          recovered medications have been recertified by MediCircle’s patented
          process and inspected by a licensed pharmacist.
        </p>
        <Button width="100%" submit={false} onClick={onContinue}>
          Get started
        </Button>
      </div>
    </div>
  );
}
