import instance from "./http_client";

export function verifyPatient(
  dob: string,
  firstName: string,
  lastName: string,
  accessCode: string
) {
  return instance.post(`consent/verify?access_code=${accessCode}`, {
    dob: dob,
    first_name: firstName,
    last_name: lastName,
  });
}
