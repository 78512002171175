import { useForm } from "react-hook-form";
import styles from "./PatientAuthentication.module.css";
import { useState } from "react";
import {
  matchDayRegex,
  matchMonthRegex,
  matchYearRegex,
} from "../../constants";
import { verifyPatient } from "../../api/verify_patient_api";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useAuth } from "../../providers/AuthPatientProvider";

interface IFormInput {
  day: string;
  month: string;
  year: string;
  firstName: string;
  lastName: string;
}

export default function PatientAuthentication() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ mode: "onSubmit", reValidateMode: "onSubmit" });

  const { loadPatient } = useAuth();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [networkError, setNetworkError] = useState("");

  const onSubmit = (data: IFormInput) => {
    const date = `${data.year}-${data.month}-${data.day}`;
    const accessCode = searchParams.get("access_code") ?? "";

    setLoading(true);
    return verifyPatient(date, data.firstName, data.lastName, accessCode)
      .then(loadPatient)
      .then(() => navigate("/confirmInformation"))
      .catch((e) => {
        if (e.response && e.response.status === 409) {
          setNetworkError("No forms to sign");
        } else {
          setNetworkError("Invalid credentials");
        }
      })
      .finally(() => setLoading(false));
  };

  const dobFormatError =
    errors.day || errors.month || errors.year
      ? "Please enter a valid date"
      : "";

  const nameRequiredError =
    errors.firstName || errors.lastName
      ? "Please enter your first and last name"
      : "";

  return (
    <form
      className={styles.identityConfirmation}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.content}>
        <h1 className={styles.title}>Verify your identity</h1>
        <p className={styles.subtext}>
          Please enter your first name, last name, and date of birth so that we
          can verify your identity.
        </p>
        <div className={styles.inputsBox}>
          <div>
            <p className={styles.label}>Full name</p>
            <div className={`${styles.inputs} ${styles.nameInputs}`}>
              <input
                {...register("firstName", {
                  required: true,
                })}
                placeholder="First name"
                className={errors.firstName ? styles.hasError : undefined}
              ></input>
              <input
                {...register("lastName", {
                  required: true,
                })}
                className={errors.lastName ? styles.hasError : undefined}
                placeholder="Last name"
              ></input>
            </div>
            {nameRequiredError && (
              <p className={styles.error}>{nameRequiredError}</p>
            )}
          </div>
          <div>
            <p className={styles.label}>Date of birth</p>
            <div className={styles.inputs}>
              <input
                {...register("month", {
                  pattern: matchMonthRegex,
                  required: true,
                })}
                className={`${styles.monthInput} ${
                  errors.month ? styles.hasError : ""
                }`}
                placeholder="MM"
                maxLength={2}
              ></input>
              <input
                {...register("day", { pattern: matchDayRegex, required: true })}
                className={`${styles.dayInput} ${
                  errors.day ? styles.hasError : ""
                }`}
                placeholder="DD"
                maxLength={2}
              ></input>
              <input
                {...register("year", {
                  pattern: matchYearRegex,
                  required: true,
                })}
                className={`${styles.yearInput} ${
                  errors.year ? styles.hasError : ""
                }`}
                placeholder="YYYY"
                maxLength={4}
              ></input>
            </div>
            {dobFormatError && <p className={styles.error}>{dobFormatError}</p>}
            {networkError && <p className={styles.error}>{networkError}</p>}
          </div>
        </div>
        <Button width="100%" submit={true} loading={loading}>
          Continue
        </Button>
      </div>
    </form>
  );
}
