import axios from "axios";
import { BASE_API_URL } from "../constants";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const BASE_CONFIG = {
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

axios.defaults.withCredentials = true;

const instance = axios.create(BASE_CONFIG);

export default instance;
