import React, { FunctionComponent } from "react";
import styles from "./Button.module.css";
import BeatLoader from "react-spinners/BeatLoader";

interface ButtonProps {
  primary?: boolean;
  submit?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  pill?: boolean;
  children?: React.ReactNode;
  width?: string;
  tabIndex?: number;
  destructive?: boolean;
  loading?: boolean;
  padding?: string;
  fontSize?: string;
  disabled?: boolean;
}

const Button: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const className = `${styles.button} ${
    props.primary ? styles.primary : styles.secondary
  } ${props.pill ? styles.pill : styles.square} ${
    props.destructive ? styles.destructive : ""
  } ${props.loading ? styles.showLoader : ""}`;

  const style = {
    width: props.width,
    padding: props.padding,
    fontSize: props.fontSize,
  };

  return (
    <button
      disabled={props.disabled}
      tabIndex={props.tabIndex}
      style={style}
      type={props.submit ? "submit" : "button"}
      onClick={!props.loading && !props.disabled ? props.onClick : undefined}
      className={className}
    >
      <div className={styles.loaderBox}>
        <BeatLoader
          color={
            props.primary
              ? "white"
              : props.destructive
              ? "var(--color-destructive)"
              : "var(--color-primary)"
          }
          size={10}
        ></BeatLoader>
      </div>
      <div className={styles.children}>{props.children}</div>
    </button>
  );
};

Button.defaultProps = {
  primary: true,
  submit: false,
  pill: true,
  destructive: false,
  disabled: false,
};

export default Button;
